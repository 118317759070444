


















































import { mapState, mapGetters } from "vuex";
import Vue from "vue";
import ScoreManager from "@/ts/wt/managers/ScoreManager";
import Worldtrix from "@/ts/wt/Worldtrix";
import ScoreValue from "@/ts/wt/data/ScoreValue";

import JsonWorldtrix from "@/components/elements/WorldtrixTime.vue";

export default Vue.extend({
  name: "Datacookies",
  components: { JsonWorldtrix },
  data: () => ({
    tableHead: [
      { text: "ID", value: "sid" },
      { text: "Name", value: "name" },
      { text: "Type", value: "type" },
      {
        text: "Points",
        align: "start",
        sortable: true,
        value: "score",
      },
    ],
    scores: [
      {
        sid: "dummy",
        type: "DUMMY",
        name: "dummy",
        score: 0,
      },
    ],
    sum: 0,
    color: "",
    autoRefresh: false,
    intervalID: -1,
  }),
  computed: {
    ...mapState([]),
    ...mapGetters(["wt"]),
  },
  methods: {
    refreshScore() {
      //console.log("Score calculated...");
      this.color = "success";
      setTimeout(() => (this.color = ""), 300);
      this.scores = ScoreManager.calculateScoreList(this.wt as Worldtrix);
      this.sum = ScoreManager.calculateSum(this.scores as Array<ScoreValue>);
    },
    toggleRefresh() {
      if (this.autoRefresh) {
        //@ts-ignore
        this.intervalID = setInterval(() => this.refreshScore(), 1000);
      } else {
        clearInterval(this.intervalID);
        this.intervalID = -1;
      }
    },
  },
  created: function() {
    //lastMouseMovement = Date.now()
  },
  mounted: function() {
    this.scores = ScoreManager.calculateScoreList(this.wt as Worldtrix);
    this.sum = ScoreManager.calculateSum(this.scores as Array<ScoreValue>);
  },
  beforeDestroy: function() {
    if (this.intervalID !== -1) {
      clearInterval(this.intervalID);
    }
  },
});
