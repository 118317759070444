import WTEntity from "../entities/WTEntity";
import ScoreManager from "../managers/ScoreManager";

export default class ScoreValue {
  sid: string;
  type: string;
  score: number;
  name: string;

  constructor(
    entity: WTEntity | undefined,
    type: string,
    sid?: string,
    score?: number,
    name?: string
  ) {
    this.type = type;
    if (entity) {
      this.sid = entity.wtid.toString();
      this.score = ScoreManager.calculateEntity(entity);
      this.name = entity.title;
    } else if (sid && score && name) {
      this.sid = sid;
      this.score = score;
      this.name = name;
    } else {
      this.sid = "X";
      this.score = 0;
      this.name = "Invalid score";
    }
    this.score = Math.round((100 * this.score) / 100);
  }

  static compareScore(A: ScoreValue, B: ScoreValue): number {
    if (A.score > B.score) {
      return -1;
    }
    if (A.score < B.score) {
      return 1;
    }
    return 0;
  }

  static compareSid(A: ScoreValue, B: ScoreValue): number {
    if (A.sid < B.sid) {
      return -1;
    }
    if (A.sid > B.sid) {
      return 1;
    }
    return 0;
  }

  static compareName(A: ScoreValue, B: ScoreValue): number {
    if (A.name < B.name) {
      return -1;
    }
    if (A.name > B.name) {
      return 1;
    }
    return 0;
  }

  scoreString(): string {
    return ScoreValue.scoreString(this.score);
  }

  static scoreString(score: number): string {
    return Math.round((100 * score) / 100).toString();
  }
}
