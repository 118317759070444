import WTEntity from "../entities/WTEntity";
import * as WT from "@/ts/wt/declaration/WTEnums";
import Worldtrix from "../Worldtrix";
import Population from "../entities/Population/Population";
import Human from "../entities/Population/Human";
import Inventory from "../Inventory/Inventory";
import WGS from "../lib/WGS";
import ScoreValue from "../data/ScoreValue";

class Score {
  score: number;

  constructor(score: number) {
    this.score = score;
  }
}

export default class ScoreManager {
  static scoreValues = {
    population: {
      type: {
        BABY: 0.2,
        CHILD: 0.7,
        ADULT: 1,
      },
      saturation: {
        POSITIV: 1,
        NEGATIVE: 10,
      },
    },
    inventory: {
      FOOD: 1,
      TOOL: 2,
      MATERIAL: 0.2,
    },
    wgs: {
      MAX_DISTANCE: 300,
    },
  };

  //++++++++++++++++++++++++++++++ CALCULATIONS functions ++++++++++++++++++++++++++//

  static calculateScoreList(wt: Worldtrix): Array<ScoreValue> {
    let wtSum = 0;
    let scores: Array<ScoreValue> = [];
    let pmEntities: Array<WTEntity> = wt.pm.taskForces;
    pmEntities = pmEntities.concat(wt.pm.tribes);
    for (let entity of pmEntities) {
      scores.push(new ScoreValue(entity, "ENTITY"));
    }
    scores.push(ScoreManager.calculateWGSValue(pmEntities));
    return scores;
  }

  static calculateWGSValue(entities: Array<WTEntity>): ScoreValue {
    return new ScoreValue(
      undefined,
      "GLOBAL",
      "GLOBAL",
      ScoreManager.wgsMaxHelper(entities),
      "Max WGS-Distance between entities"
    );
  }

  static calculateSum(scores: Array<ScoreValue>) {
    let sum = 0;
    for (let s of scores) {
      sum += s.score;
    }
    return sum;
  }

  //++++++++++++++++++++++++++++++ PRINT functions ++++++++++++++++++++++++++//

  static consoleLog(wt: Worldtrix): void {
    let scores = ScoreManager.calculateScoreList(wt);
    scores = scores.sort(ScoreValue.compareScore);
    let totalSum = ScoreManager.calculateSum(scores);
    console.log("-------------------------------------------------");
    console.log("|  " + ScoreValue.scoreString(totalSum) + "  TOTAL SCORE |");
    console.log("-------------------------------------------------");
    for (let s of scores) {
      console.log(
        `|  ${s.scoreString()}  |  ${s.name}  |  ${s.sid}  |  ${s.type}  |`
      );
    }
    console.log("-------------------------------------------------");
  }

  static toStringArray(entity: WTEntity): Array<String> {
    let stringArray = [];
    stringArray.push(entity.wtid.toString());
    stringArray.push(entity.title);
    stringArray.push(
      Math.round((100 * ScoreManager.calculateEntity(entity)) / 100).toString()
    );
    return stringArray;
  }

  //++++++++++++++++++++++++++++++ score helpers ++++++++++++++++++++++++++//
  static wgsMaxHelper(entities: Array<WTEntity>): number {
    let wgsSum = 0;
    if (entities.length > 1) {
      let findMaxDistance = 0;
      let iterBase = entities.concat([]);
      for (let entity of entities) {
        let iterEnts = iterBase.concat([]);
        for (let e2 of iterEnts) {
          let distance = WGS.distance(e2.wgs, entity.wgs);
          if (findMaxDistance < distance) {
            findMaxDistance = distance;
          }
        }
      }
      wgsSum += findMaxDistance * ScoreManager.scoreValues.wgs.MAX_DISTANCE;
    }
    return wgsSum;
  }

  static calculateEntity(entity: WTEntity): number {
    let entitySum = 0;
    if (entity.inventory) {
      entitySum += ScoreManager.inventoryHelper(entity.inventory);
    } else {
      console.warn(`ScoreManager: Entity ${entity.wtid} has no inventory`);
    }
    if (entity.population) {
      entitySum += ScoreManager.popluationHelper(entity.population);
    } else {
      console.warn(`ScoreManager: Entity ${entity.wtid} has no population`);
    }
    return entitySum;
  }

  private static inventoryHelper(inventory: Inventory): number {
    let invScore = 0;
    invScore +=
      inventory.getAmountOfItemType(WT.Inventory.Type.FOOD) *
      ScoreManager.scoreValues.inventory.FOOD;
    invScore +=
      inventory.getAmountOfItemType(WT.Inventory.Type.MATERIAL) *
      ScoreManager.scoreValues.inventory.MATERIAL;
    invScore +=
      inventory.getAmountOfItemType(WT.Inventory.Type.TOOL) *
      ScoreManager.scoreValues.inventory.TOOL;
    return invScore;
  }

  private static popluationHelper(pop: Population): number {
    let popScore = 0;
    for (let human of pop.humans) {
      popScore += ScoreManager.humanHelper(human);
    }
    return popScore;
  }

  private static humanHelper(human: Human): number {
    let humanScore = 0;
    switch (human.ageState) {
      case WT.Human.AgeState.ADULT:
        humanScore += ScoreManager.scoreValues.population.type.ADULT;
        break;
      case WT.Human.AgeState.CHILD:
        humanScore += ScoreManager.scoreValues.population.type.CHILD;
        break;
      case WT.Human.AgeState.BABY:
        humanScore += ScoreManager.scoreValues.population.type.BABY;
        break;
      default:
        break;
    }
    if (human.foodSaturation > 0) {
      humanScore +=
        human.foodSaturation *
        ScoreManager.scoreValues.population.saturation.POSITIV;
    } else {
      humanScore +=
        human.foodSaturation *
        ScoreManager.scoreValues.population.saturation.NEGATIVE;
    }
    return humanScore;
  }
}
